import React, { Component } from "react";
import classNames from "classnames";
import "./index.scss";

class Message extends Component {
  render() {
    const cssClass = classNames("message", {
      "message--error": this.props.type === "error",
      "message--center": this.props.center,
      "message--large": this.props.large,
    });

    return (
      <div className={cssClass}>
        <p className="message__content">{this.props.message}</p>
        {this.props.children}
      </div>
    );
  }
}

export default Message;

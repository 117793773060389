import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "../modal";
import PageControl from "../page-control";
import DownloadIcon from "../../../images/svg/download.svg";
import "./index.scss";

function CSVExport(props) {
  const {
    currentRows,
    allRows,
    licenseInfo,
    tablePageSize,
    isBookCreatorUser,
  } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPageOnly, setCurrentPageOnly] = useState(true);

  const headings = ["Email", "Name", "Signed up", "License", "Certification"];

  // Temporarily restricting the book count for BC users only
  if (isBookCreatorUser) {
    headings.splice(2, 0, "Books");
  }

  let multiplePages = allRows.length > tablePageSize;

  let csv = `${headings.join(",")}\n`;

  let csvData = currentPageOnly ? currentRows : allRows;

  let rows = csvData.map(row => {
    const certified =
      row.badges && (row.badges.calevel1 || row.badges.calevel2);
    const certifying = row.certifying;
    const rowLicenseInfo = licenseInfo?.filter(
      info => info.id === row.license?.id
    );
    const licenseBookCount =
      row.license && rowLicenseInfo?.length
        ? rowLicenseInfo[0].bookQuota
        : null;

    const data = [
      row.email,
      `"${row.displayName || ""}"`,
      !row.pending ? "Yes" : "",
      licenseBookCount ? `${licenseBookCount} books` : "",
      certified ? "Complete" : certifying ? "In progress" : "",
    ];

    if (isBookCreatorUser) {
      data.splice(2, 0, row.bookCount);
    }
    return data.join(",");
  });

  csv += rows.join("\n");

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const component = (
    <div className="csv-export">
      {multiplePages ? (
        <PageControl
          icon={DownloadIcon}
          iconName="download"
          onClick={() => setShowConfirmation(true)}
          text="Export as CSV"
        />
      ) : (
        <PageControl
          href={url}
          icon={DownloadIcon}
          iconName="download"
          download="teacher-export.csv"
          text="Export as CSV"
        />
      )}
      {showConfirmation ? (
        <Modal
          modal
          onCancel={() => setShowConfirmation(false)}
          confirmButtonHref={url}
          confirmButtonDownload="teacher-export.csv"
          onConfirm={() => setShowConfirmation(false)}
          title="Export as CSV">
          <div className="csv-export-confirmation">
            <label>
              <input
                type="radio"
                checked={currentPageOnly}
                onChange={() => setCurrentPageOnly(!currentPageOnly)}
              />
              Export current page ({currentRows.length}{" "}
              {currentRows.length === 1 ? "teacher" : "teachers"})
            </label>
            <label>
              <input
                type="radio"
                checked={!currentPageOnly}
                onChange={() => setCurrentPageOnly(!currentPageOnly)}
              />
              Export all teachers ({allRows.length}{" "}
              {allRows.length === 1 ? "teacher" : "teachers"})
            </label>
          </div>
        </Modal>
      ) : null}
    </div>
  );

  const container = document.getElementById("page-nav-container");

  return container ? ReactDOM.createPortal(component, container) : null;
}

export default CSVExport;

import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Licenses from "../pages/licenses";
import Teachers from "../pages/teachers";
import Portfolios from "../pages/portfolios";
import Admin from "../pages/admin";
import Analytics from "../pages/analytics";
import Resources from "../pages/resources";
import LMS from "../pages/lms";
import AppManagementPage from "../pages/apps/container";
import Buttons from "../pages/buttons/container";
import APIKeysPage from "../pages/api-keys/container";
import ButtonSDKDocs from "../pages/docs/button-sdk";
import ImageSearchPage from "../pages/image-search";
import "./main.scss";

export default class Main extends Component {
  render() {
    const { store, isButtonConsumer } = this.props;

    return (
      <div className="main">
        <Switch>
          <Route path="/teachers" component={Teachers} store={store} />
          <Route path="/portfolios" component={Portfolios} store={store} />
          <Route path="/admin" component={Admin} store={store} />
          <Route path="/analytics" component={Analytics} store={store} />
          <Route path="/resources" component={Resources} store={store} />
          <Route path="/lms" component={LMS} store={store} />
          <Route path="/apps" component={AppManagementPage} store={store} />
          <Route
            path="/image-search"
            component={ImageSearchPage}
            store={store}
          />
          <Route path="/api-keys" component={APIKeysPage} store={store} />
          <Route path="/buttons" component={Buttons} store={store} />
          <Route
            path="/docs/button-sdk"
            component={ButtonSDKDocs}
            store={store}
          />

          {isButtonConsumer ? (
            <Redirect from="/" to="/api-keys" />
          ) : (
            <Route path="/" component={Licenses} store={store} />
          )}
        </Switch>
      </div>
    );
  }
}

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import flow from "lodash.flow";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Redirect, withRouter } from "react-router-dom";
import {
  isAuthenticated,
  getAuth,
  getAuthToken,
  getIsBookCreatorUser,
  getEmailVerified,
} from "../../core/auth";
import {
  getNotifications,
  notificationsActions,
} from "../../core/notifications";
import {
  getIsButtonConsumer,
  getOrganisationLoaded,
  getShowOrgPicker,
  organisationActions,
  getOrganisationId,
} from "../../core/organisation";
import { NotificationStack } from "react-notification";
import Main from "./main";
import Nav from "../components/nav";
import Intercom from "../components/intercom";
import "../../common/styles/colors.scss";
import NotVerified from "./not-verified/container";
import OrganisationPickerModal from "../components/sign-in/organisation-picker/modal/container";
import "./index.scss";

function App(props) {
  const {
    allNotifications,
    auth,
    authenticated,
    authToken,
    getOrganisations,
    isBookCreatorUser,
    isButtonConsumer,
    location,
    organisationId,
    organisationLoaded,
    removeNotification,
    showOrgPicker,
    store,
    verified,
  } = props;

  const organisationRef = useRef();
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    if (authToken) {
      getOrganisations();
    }
  }, [authToken, getOrganisations]);

  useEffect(() => {
    if (
      organisationRef.current &&
      organisationRef.current !== organisationId &&
      !redirect
    ) {
      setRedirect("/");
    }
    organisationRef.current = organisationId;
  }, [organisationId, redirect]);

  useEffect(() => {
    if (redirect) {
      setRedirect(null);
    }
  }, [redirect]);

  if (!authenticated) {
    return <Redirect to="/sign-in" />;
  }

  const notifications = allNotifications.map(notification => {
    notification.onClick = removeNotification.bind(this, notification.key);
    return notification;
  });

  return (
    <div className="book-creator-admin" data-id={auth.id}>
      {redirect && <Redirect to={redirect} />}
      <Nav auth={auth} routeKey={location.key} />
      <Main store={store} isButtonConsumer={isButtonConsumer} />
      <NotificationStack
        notifications={notifications.toArray()}
        onDismiss={notification => {}}
      />
      {auth.initialised && organisationLoaded && !isBookCreatorUser ? (
        <Intercom />
      ) : null}
      {auth.initialised && !verified ? <NotVerified /> : null}
      {showOrgPicker ? <OrganisationPickerModal /> : null}
    </div>
  );
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  getAuthToken,
  getIsButtonConsumer,
  getNotifications,
  getOrganisationLoaded,
  getIsBookCreatorUser,
  getEmailVerified,
  getShowOrgPicker,
  getOrganisationId,
  (
    authenticated,
    auth,
    authToken,
    isButtonConsumer,
    allNotifications,
    organisationLoaded,
    isBookCreatorUser,
    verified,
    showOrgPicker,
    organisationId
  ) => ({
    authenticated,
    auth,
    authToken,
    isButtonConsumer,
    allNotifications,
    organisationLoaded,
    isBookCreatorUser,
    verified,
    showOrgPicker,
    organisationId,
  })
);

const mapDispatchToProps = {
  ...notificationsActions,
  ...organisationActions,
};

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

App.propTypes = {
  allNotifications: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    id: PropTypes.string,
    initialised: PropTypes.bool.isRequired,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
  authToken: PropTypes.string,
  getOrganisations: PropTypes.func.isRequired,
  isBookCreatorUser: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  organisationLoaded: PropTypes.bool.isRequired,
  removeNotification: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
};

export default decorators(App);

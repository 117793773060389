import React, { useEffect } from "react";
import Modal from "../../../common/components/Modal";
import { firebaseAuth, sendEmailVerification } from "../../../core/firebase";
import "./index.scss";

function NotVerified(props) {
  const { setVerified, getAuthToken } = props;

  useEffect(() => {
    let interval;
    const { emailVerified } = firebaseAuth.currentUser;
    const checkVerification = () => {
      if (!firebaseAuth.currentUser) {
        return;
      }
      const { emailVerified } = firebaseAuth.currentUser;
      if (emailVerified) {
        clearInterval(interval);
        getAuthToken();
        setTimeout(() => {
          setVerified(emailVerified);
        }, 5000);
      }
      firebaseAuth.currentUser.reload();
    };

    interval = setInterval(checkVerification, 10000);
    if (emailVerified) {
      setVerified(emailVerified);
    } else {
      sendEmailVerification(firebaseAuth.currentUser);
    }
    return () => {
      clearInterval(interval);
    };
  }, [setVerified, getAuthToken]);

  return (
    <Modal title="Email verification">
      <p style={{ padding: "20px 30px" }}>
        You need to verify your email to use this site. We have sent you an
        email, please click the link in it to continue.
      </p>
    </Modal>
  );
}

export default NotVerified;

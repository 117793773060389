import React, { Component } from "react";
import Helmet from "react-helmet";
import superagent from "superagent";
import { BOOKCREATOR_API_URL } from "../../../config";
import { Link, withRouter } from "react-router-dom";
import {
  getEmailError as emailErrorValidator,
  getPasswordError,
} from "../../../utils/validators";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import SignInButton from "./sign-in-button";
import TextBox from "../../components/forms/text-box";
import Message from "../ui/message";

class SignInWithEmail extends Component {
  constructor(props) {
    super(props);
    const { email } = props.computedMatch.params;
    this.state = {
      error: null,
      email: email ? email : "",
      password: "",
      submitted: false,
      submitting: false,
      alreadyRegistered: null,
      alreadyRegisteredWithGoogle: null,
      alreadyRegisteredWithOffice: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.error) {
      this.setState({
        submitting: false,
      });
    }
  }

  updateEmail(nextEmail) {
    this.props.clearAuthError();
    this.setState({
      email: nextEmail,
    });
  }

  updateName(nextName) {
    this.props.clearAuthError();
    this.setState({
      fullName: nextName,
    });
  }

  updatePassword(nextPassword) {
    this.props.clearAuthError();
    this.setState({
      password: nextPassword,
    });
  }

  checkIfUserExists() {
    return new Promise((resolve, reject) => {
      const { email } = this.state;
      this.getProviders(email)
        .catch(error => {
          resolve(false);
        })
        .then(data => {
          resolve(data);
        });
    });
  }

  getProviders = email => {
    return new Promise((resolve, reject) => {
      superagent
        .get(`${BOOKCREATOR_API_URL}/users/v2/providers/${email}`)
        .withCredentials()
        .end((err, res) => {
          if (err) {
            reject();
          } else {
            if (res && res.body && res.body.length) {
              resolve(res.body);
            } else {
              reject();
            }
          }
        });
    });
  };

  getEmailError = email => {
    // district specific exception
    if (email.trim().endsWith(".nyc.gov")) {
      return "Your district prevents the use of email and password logins to Book Creator Admin Console, please use the preferred Single Sign On methods";
    }
    if (
      email.trim().endsWith("@lausd.net") ||
      email.trim().endsWith("@mymail.lausd.net")
    ) {
      return "Please sign in using your district SSO such as Clever or Schoology";
    }
    return emailErrorValidator(email);
  };

  handleSubmit(e) {
    const { history } = this.props;
    const {
      alreadyRegistered,
      alreadyRegisteredWithGoogle,
      email,
      password,
    } = this.state;
    this.setState({
      submitted: true,
    });
    e.preventDefault();
    if (!this.isValid()) {
      return;
    }
    if (alreadyRegistered) {
      this.setState({
        submitting: true,
      });
      this.props.signInWithEmailAndPassword({
        email,
        password,
      });
    } else if (!alreadyRegisteredWithGoogle) {
      this.checkIfUserExists().then(data => {
        if (data && data.includes("password")) {
          this.setState({
            alreadyRegistered: true,
            submitted: false,
          });
        } else if (data && data.includes("google.com")) {
          this.setState({
            alreadyRegisteredWithGoogle: true,
          });
        } else if (data && data.includes("office365")) {
          this.setState({
            alreadyRegisteredWithOffice: true,
          });
        } else {
          history.replace(`/sign-in/register/${email}`);
        }
      });
    }
  }

  isValid() {
    const { alreadyRegistered, email, password } = this.state;

    const { auth } = this.props;
    const { error } = auth;

    if (alreadyRegistered) {
      return (
        !error && !this.getEmailError(email) && !getPasswordError(password)
      );
    } else {
      return !error && !this.getEmailError(email);
    }
  }

  render() {
    const {
      email,
      password,
      submitted,
      submitting,
      alreadyRegistered,
      alreadyRegisteredWithGoogle,
      alreadyRegisteredWithOffice,
    } = this.state;

    const { signInWithGoogle, auth } = this.props;

    const { error } = auth;

    let emailError = submitted && this.getEmailError(email);
    let passwordError = submitted && getPasswordError(password);
    const hasError = error?.code || emailError;

    if (error && error.code) {
      switch (error.code) {
        case "auth/wrong-password":
          passwordError = "Incorrect password";
          break;
        case "auth/internal-error":
          emailError = error.message;
          break;
        case "auth/user-not-found":
          emailError = "That email address doesn't match an existing account";
          break;
        case "auth/invalid-email":
          emailError = "Invalid email address";
          break;
        case "auth/user-disabled":
          emailError = "This account has been disabled";
          break;
        case "auth/too-many-requests":
          passwordError =
            "You have entered an incorrect password too many times. Please try again in a few minutes.";
          break;
        default:
          passwordError = "There was a problem signing in, please try again.";
          break;
      }
    }

    const submitText = alreadyRegistered ? "Sign in" : "Next";

    const cancelLink = "/sign-in";
    const forgottenPasswordLink = "/sign-in/forgotten-password";

    return (
      <Form header="Sign in with email" onSubmit={this.handleSubmit.bind(this)}>
        <Helmet title="Sign in with an email address" />
        <div className="form__content">
          {alreadyRegisteredWithGoogle ? (
            <div>
              <p>
                That email address has already been registered with a Google
                account
              </p>
              <SignInButton
                text="Sign in with Google"
                onClick={signInWithGoogle}
                provider="google"
              />
            </div>
          ) : alreadyRegisteredWithOffice ? (
            <div>
              <p>
                That email address has already been registered with an Office365
                account
              </p>
              <SignInButton
                href="/sign-in/office-365"
                text="Sign in with Office 365"
                provider="office"
              />
            </div>
          ) : (
            <TextBox
              label="Email"
              type="email"
              name="email"
              onChange={this.updateEmail.bind(this)}
              value={email}
              autoFocus
              error={emailError}
            />
          )}
          {alreadyRegistered ? (
            <TextBox
              label="Password"
              type="password"
              name="password"
              onChange={this.updatePassword.bind(this)}
              value={password}
              autoFocus
              error={passwordError}
            />
          ) : null}
          {hasError ? (
            <Message type="error" center>
              {emailError || passwordError}
            </Message>
          ) : null}
        </div>
        <div className="form__actions">
          {alreadyRegistered ? (
            <small className="form__actions-smallprint">
              <Link to={forgottenPasswordLink}>Forgotten password?</Link>
            </small>
          ) : null}
          <FormButton flat href={cancelLink} text="Cancel" />
          {!alreadyRegisteredWithGoogle ? (
            <FormButton type="submit" text={submitText} loading={submitting} />
          ) : null}
        </div>
      </Form>
    );
  }
}

export default withRouter(SignInWithEmail);

import React from "react";
import MiniMenuItem from "../../ui/mini-menu/item";
import LicenseIndicator from "../license-indicator";
import { OrganisationRole } from "../library-menu/const";

const MenuItems = props => {
  const {
    featureFlags,
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    licenseInfo,
    showLicenseSubMenu,
    onAddLibraryManagerRoleClick,
    onRemoveLibraryManagerRoleClick,
    onLicenseParentClick,
    onLicenseItemClick,
    onSendWelcomeEmailClick,
    onRemoveLicenseClick,
    onRemoveTeacherClick,
    onRequestCertificationClick,
    onSetUsersToDowngrade,
    showViewProfile,
    onViewProfileClick,
    role,
    teacher,
  } = props;

  if (showLicenseSubMenu) {
    return (
      <div>
        {licenseInfo.toJS().map(license => {
          const { bookQuota, libraryQuota, quantity, allocated } = license;
          const available = isApplyToAllLicense
            ? "Unlimited"
            : quantity - allocated;
          return (
            <MiniMenuItem
              key={license.id}
              onClick={e => onLicenseItemClick(license)}>
              <LicenseIndicator
                bookQuota={bookQuota}
                libraryQuota={libraryQuota}
                available={available}
              />
            </MiniMenuItem>
          );
        })}
      </div>
    );
  }
  return (
    <div>
      {showViewProfile ? (
        <MiniMenuItem
          onClick={e => {
            onViewProfileClick();
          }}>
          View Profile
        </MiniMenuItem>
      ) : null}
      {!hasDomainLicense ? (
        <MiniMenuItem
          hasSubMenu={licenseInfo?.size}
          disabled={isApplyToAllLicense || !licenseInfo?.size}
          onClick={e => {
            onLicenseParentClick();
            e.stopPropagation();
          }}>
          Assign license
        </MiniMenuItem>
      ) : null}
      {!hasDomainLicense || isBookCreatorUser ? (
        <MiniMenuItem onClick={onSendWelcomeEmailClick}>
          Send welcome email
        </MiniMenuItem>
      ) : null}
      <MiniMenuItem onClick={onRequestCertificationClick}>
        Request Certification
      </MiniMenuItem>
      {!hasDomainLicense ? (
        <MiniMenuItem
          onClick={onRemoveLicenseClick}
          disabled={teacher && !teacher.license}>
          Remove license
        </MiniMenuItem>
      ) : null}
      {featureFlags.orgLibraries && role === OrganisationRole.TEACHER ? (
        <MiniMenuItem onClick={onAddLibraryManagerRoleClick}>
          Assign as Library Manager
        </MiniMenuItem>
      ) : null}
      {featureFlags.orgLibraries && role === OrganisationRole.MANAGER ? (
        <MiniMenuItem onClick={onRemoveLibraryManagerRoleClick}>
          Remove as Library Manager
        </MiniMenuItem>
      ) : null}
      <MiniMenuItem onClick={onSetUsersToDowngrade} disabled={teacher?.pending}>
        Switch to student
      </MiniMenuItem>
      {!hasDomainLicense || isBookCreatorUser ? (
        <MiniMenuItem onClick={onRemoveTeacherClick} type="negative">
          Remove teacher
        </MiniMenuItem>
      ) : null}
    </div>
  );
};

export default MenuItems;

import React from "react";
import { connect } from "react-redux";
import { authActions } from "../../../core/auth";
import NotVerified from "./";

function NotVerifiedContainer(props) {
  return <NotVerified {...props} />;
}

const mapDispatchToProps = {
  ...authActions,
};

export default connect(null, mapDispatchToProps)(NotVerifiedContainer);

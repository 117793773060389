import React, { Component } from "react";
import classNames from "classnames";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import "./index.scss";

export default class MiniMenuItem extends Component {
  render() {
    const {
      children,
      icon,
      iconName,
      onClick,
      type,
      compact,
      disabled,
      href,
      target,
      iconHighlight,
      hasSubMenu,
    } = this.props;

    const cssClass = classNames("mini-menu__action", {
      [`mini-menu__action--${type}`]: type,
      "mini-menu__action--compact": compact,
      "mini-menu__action--highlight": iconHighlight,
      "mini-menu__action--has-submenu": hasSubMenu,
    });

    return (
      <a
        href={href}
        className={cssClass}
        disabled={disabled}
        onClick={onClick}
        target={target}
        onContextMenu={e => e.stopPropagation()}>
        {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
        {children}
        {hasSubMenu ? <SvgIcon icon={ChevronIcon} name="sub-menu" /> : null}
      </a>
    );
  }
}

import { Record } from "immutable";

import {
  INIT_AUTH,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_IN_ERROR,
  CACHE_AUTH_TOKEN,
  CLEAR_AUTH_ERROR,
  SET_USER_ORG_ROLES,
  OFFICE_365_ERROR,
  SET_VERIFIED,
} from "./action-types";

export const AuthState = new Record({
  authenticated: false,
  authToken: null,
  avatar: null,
  displayName: null,
  email: null,
  error: null,
  id: null,
  initialised: false,
  lang: "en",
  office365Error: null,
  organisationRoles: {},
  ready: false,
  verified: false,
});

export function authReducer(state = new AuthState(), { payload, type }) {
  switch (type) {
    case INIT_AUTH:
    case SIGN_IN_SUCCESS:
      return state.merge({
        initialised: true,
        authenticated: !!payload,
        id: payload ? payload.uid : null,
        avatar: payload && payload.photoURL ? payload.photoURL : null,
        displayName:
          payload && payload.displayName ? payload.displayName : null,
        email: payload && payload.email ? payload.email : null,
      });
    case SIGN_IN_ERROR:
      return state.merge({ error: payload });
    case SIGN_OUT_SUCCESS:
      let nextState = new AuthState();
      if (payload && payload.preserveError) {
        nextState = nextState.set("error", state.error);
      }
      nextState = nextState.set("initialised", true);
      return nextState;
    case CACHE_AUTH_TOKEN:
      return state.merge({
        authToken: payload,
      });
    case SET_USER_ORG_ROLES:
      return state.merge({
        organisationRoles: payload,
      });
    case CLEAR_AUTH_ERROR:
      return state.merge({
        error: null,
      });
    case OFFICE_365_ERROR:
      return state.merge({
        office365Error: payload,
      });
    case SET_VERIFIED:
      return state.merge({
        verified: payload,
      });
    default:
      return state;
  }
}

import HomeIcon from "../../../images/svg/home.svg";
import TeacherIcon from "../../../images/svg/teacher.svg";
import SettingsIcon from "../../../images/svg/settings.svg";
import DiscoverIcon from "../../../images/svg/discover.svg";
import AnalyticsIcon from "../../../images/svg/analytics.svg";
import ButtonIcon from "../../../images/svg/button.svg";
import DocumentIcon from "../../../images/svg/document.svg";
import KeyIcon from "../../../images/svg/key.svg";
import SignOutIcon from "../../../images/svg/sign-out.svg";
import PortfoliosIcon from "../../../images/svg/portfolios.svg";

const getRoutes = ({ signOut, isButtonConsumer, hasPortfoliosEnabled }) => {
  let routes = [];

  const analyticsRoute = {
    name: "Analytics",
    icon: AnalyticsIcon,
    iconName: "analytics",
    route: "/analytics",
  };

  if (isButtonConsumer) {
    routes = [
      {
        name: "API Keys",
        icon: KeyIcon,
        iconName: "key",
        route: "/api-keys",
      },
      {
        name: "Buttons",
        icon: ButtonIcon,
        iconName: "buttons",
        route: "/buttons",
      },
      {
        name: "SDK Documentation",
        icon: DocumentIcon,
        iconName: "docs",
        route: "/docs/button-sdk",
      },
      analyticsRoute,
    ];
  } else {
    routes = [
      {
        name: "Home",
        icon: HomeIcon,
        iconName: "home",
        route: "/",
      },
      {
        name: "Teachers",
        icon: TeacherIcon,
        iconName: "teacher",
        route: "/teachers",
      },
      analyticsRoute,
      {
        name: "Resources",
        icon: DiscoverIcon,
        iconName: "discover",
        childRoutes: [
          {
            name: "For Admins",
            href: "https://intercom.help/bookcreator/for-administrators",
          },
          {
            name: "For Teachers",
            route: "/resources",
          },
        ],
      },
      {
        name: "Settings",
        icon: SettingsIcon,
        iconName: "settings",
        childRoutes: [
          {
            name: "Admins",
            route: "/admin",
          },
          {
            name: "LMS Integration",
            route: "/lms",
          },
          {
            name: "Manage Apps",
            route: "/apps",
          },
        ],
      },
    ];

    if (hasPortfoliosEnabled) {
      routes.splice(2, 0, {
        name: "Portfolios",
        icon: PortfoliosIcon,
        iconName: "portfolios",
        route: "/portfolios",
      });
    }
  }

  routes.push({
    name: "Sign out",
    icon: SignOutIcon,
    iconName: "sign-out",
    onClick: signOut,
  });

  return routes;
};

export default getRoutes;

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCDn1ZFaB4HnQTCZmjHoH-0JIXXPHGvxVM",
  authDomain: "app.bookcreator.com",
  databaseURL: "https://book-creator.firebaseio.com",
  storageBucket: "book-creator.appspot.com",
  messagingSenderId: null,
};

export const PAYMENTS_DOMAIN = "https://payments.bookcreator.com";
export const LIBRARIES_DOMAIN = "https://libraries.api.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.bookcreator.com";
export const BUTTON_DOMAIN = "https://button.api.bookcreator.com";
export const BUTTON_SDK_DOMAIN = "https://sdk.bookcreator.com";
export const BUTTON_SDK_VERSION = 1;
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.bookcreator.com";
export const BOOKCREATOR_ASSETS_URL = "https://assets.api.bookcreator.com";
export const BOOKCREATOR_APP_URL = "https://app.bookcreator.com";
export const FULLSTORY_ORG_ID = "o-1H302S-na1";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "__admin_session";
export const APP_AUTH_COOKIE_NAME = "__session";
export const AUTH_COOKIE_DOMAIN = "bookcreator.com";
export const LTI_DOMAIN = "lti.bookcreator.com";

export const RECURLY_URL = "https://bookcreator.recurly.com/subscribe/";
export const OFFICE_365_CLIENT_ID = "d3deab54-9027-493c-a886-436e55df440f";

export const INTERCOM_APP_ID = "ahxpwq4j";
